<template>
  <div>
    <v-sheet class="header-backup"></v-sheet>
    <v-app-bar
      :color="$vuetify.theme.dark ? 'dark' : getThemeMode.appBarColor"
      :dark="getThemeMode.appBarColor != 'white' ? true : false"
      app
      class="px-sm text-left shadow-sm pr-0"
      flat
      height="60"
    >
        <v-app-bar-nav-icon v-if="!getHeaderIconHide && !$router.currentRoute.fullPath.includes('/customer-portal/')"
            v-ripple="{ class: 'primary--text' }"
            @click="toggleVerticalSidebarDrawer"
        />
        <v-img v-if="(!$router.currentRoute.fullPath.includes('/customer-portal/')) || ($router.currentRoute.fullPath.includes('/customer-portal/') && selectedAgent.logo)"
            :src="!$router.currentRoute.fullPath.includes('/customer-portal/') ? '@/assets/images/scanspek-logo.png' : selectedAgent.logo"
            class="flex-grow-0 d-none d-lg-block d-xl-block d-md-block d-sm-block" width="165" height="31" contain
            :class="$router.currentRoute.name == 'Quote Invoice' ? 'cursor-pointer' : ''"
            @click="$router.currentRoute.name == 'Quote Invoice' && goScanPage()"/>
        <v-progress-linear
            :active="getThemeMode.isLoading"
            :indeterminate="getThemeMode.isLoading"
            absolute
            bottom
            color="primary"
        />
        <v-spacer />
        <v-google-translate
          :dropdownStyle="dropdownStyle"
          :defaultLanguageCode="defaultLanguageCode"
          :languages="availableLanguages"
          :fetchBrowserLanguage="false"
        />
        <v-chip v-if="!$router.currentRoute.fullPath.includes('/customer-portal/')"
            pill
            class="transparent rounded-pill py-6 text-capitalize"
            @click="userDrawer = !userDrawer"
        >
            <v-avatar class="mr-2">
                <v-img src="@/assets/images/1.png"></v-img>
            </v-avatar>
            {{ user && user.name ? user.name : 'User' }}
        </v-chip>
    </v-app-bar>
    <!-- userDrawer -->
    <v-navigation-drawer
      v-model="userDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="350"
    >
      <user-drawer>
        <template v-slot:userDrawerCloseButton>
          <v-btn icon color @click.stop="userDrawer = !userDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </user-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <v-btn block class="shadow-sm primary lighten-5 primary--text" @click="logoutUser">
            <v-icon class="transition-none" >mdi-logout</v-icon>
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HeaderLayout",
  components: {
    UserDrawer: () => import("@/layouts/common-drawer/UserDrawer"),
  },
  computed: {
    ...mapGetters(["getThemeMode", 'getHeaderIconHide', 'selectedAgent']),
  },
  data() {
    return {
      dropdownStyle: { left: "auto", right: 0 },
      defaultLanguageCode: "en",
      availableLanguages: [
        {
          code: "en",
          name: "English",
        },
        {
          code: "ja",
          name: "Japanese",
        },
      ],
      user: null,
      userDrawer: false,
      searchDrawer: false,
    };
  },
  created () {
    this.user = this.$helpers.getCurData('curUser');
  },
  methods: {
    ...mapActions(["changeVerticalSidebarDrawer", "changeHideHeaderIcon",
      "changeVerticalSidebarMini", "signOut", 'updateVerticalSidebarDrawer']),
    toggleVerticalSidebarDrawer() {
      this.changeVerticalSidebarDrawer();
    },
    logoutUser() {
      this.$helpers.logout();
      this.$router.push("/login");
    },
    goScanPage() {
      this.changeHideHeaderIcon(false);
      this.updateVerticalSidebarDrawer(true);
      this.$router.push('/scans');
    }
  },
};
</script>

<style lang="scss">
.header-backup {
  display: block;
  width: 100%;
  height: 'auto';
  position: fixed;
  top: 0;
  z-index: 5;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 97.3%, 0.95) 44%,
    hsla(0, 0%, 97.3%, 0.46) 73%,
    hsla(0, 0%, 100%, 0)
  ) !important;
  left: 0;
  &.theme--dark {
    background: linear-gradient(
      180deg,
      rgba(5, 5, 5, 0.95) 44%,
      rgba(0, 0, 0, 0.46) 3%,
      hsla(0, 0%, 100%, 0)
    ) !important;
  }
}
.tippy-box[data-theme~="light"] {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.vnb {
  background: transparent !important;
  &__menu-options {
    margin-top: 3px !important;
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        &:hover {
          color: #0081ff;
          .vnb__menu-options__option__arrow {
            fill: #0081ff;
          }
        }

        &__icon {
          svg {
            fill: #0081ff !important;
          }
        }
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        color: #000 !important;
        &:hover {
          color: #0081ff !important;
        }
      }
    }
  }
}

.vnb__collapse-button {
  &:focus {
    border: 1px solid none;
    outline: none;
  }
  &:after {
    content: "\F035C";
    font-size: 25px;
    font-weight: 600;
    font-family: "Material Design Icons";
  }
  svg {
    display: none !important;
  }
}

.vnb__popup {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll !important;
  .vnb__popup__top__close-button {
    &:focus {
      border: 1px solid none;
      outline: none;
    }
    top: 20px;
    right: -33px;
    svg {
      fill: #000 !important;
    }
  }
}
</style>
